<script setup>
import { Link } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    href: {
        type: String,
        required: true,
        default: null,
    },
    variant: {
        type: String,
        default: 'primary',
    }
});

const classes = computed(() => {
    let shared = [
        'flex', 'w-full', 'h-16', 'justify-center', 'items-center',
        'cursor-pointer', 'font-medium',
        'rounded-b-xl', 'rounded-t-none',
        'transition', 'ease-in-out', 'duration-150',
    ];

    const classes = {
        primary: ['bg-gray-800', 'text-white', 'hover:bg-gray-900', 'active:bg-gray-900', 'disabled:bg-gray-600'],

        green: ['bg-green-500', 'text-white', 'hover:bg-green-600', 'active:bg-green-600', 'disabled:bg-green-300'],
        red: ['bg-red-500', 'text-white', 'hover:bg-red-600', 'active:bg-red-600', 'disabled:bg-red-300'],
        orange: ['bg-orange-500', 'text-white', 'hover:bg-orange-600', 'active:bg-orange-600', 'disabled:bg-orange-300'],
        blue: ['bg-blue-500', 'text-white', 'hover:bg-blue-600', 'active:bg-blue-600', 'disabled:bg-blue-300'],

        light: ['bg-gray-200', 'text-gray-800', 'hover:bg-gray-300', 'active:bg-gray-300', 'disabled:bg-gray-300'],
        dark: ['bg-gray-800', 'text-gray-200', 'hover:bg-gray-900', 'active:bg-gray-900', 'disabled:bg-gray-900'],
    };

    return shared.join(' ') + ' ' + classes[props.variant].join(' ');
});
</script>

<template>
    <Link :href="href" :class="classes" as="button">
        <slot />
    </Link>
</template>
